import React from "react";
import app from "../../package.json";

// custom components
import Block from "./Block";

const Footer = () => {
  return (
    <footer>
      <Block space="between" className="footer">
        <Block className="footer-terms self-end">
          <a
            href="https://ui8.net/terms"
            rel="noopener noreferrer"
            target="_blank"
          >
            &copy; 2020, UI8 LLC. ({app.version})
          </a>
        </Block>
        <Block center className="footer-purchase">
          <a href="https://ui8.net" rel="noopener noreferrer" target="_blank">
            Purchase Full Library
          </a>
        </Block>
        <Block row right space='between' className="footer-links self-end">
          <a
            href="https://ui8.net/licensing?status=6"
            rel="noopener noreferrer"
            target="_blank"
          >
            Licensing
          </a>
          <a
            href="https://ui8.net/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </Block>
      </Block>
    </footer>
  );
};

export default Footer;

import React, { useState, useCallback, useEffect } from "react";
import fileSaver from "file-saver";
import { svg2png } from "svg-png-converter";
import "./styles/theme.scss";

// images
import { Block, Header, Footer, Icon } from "./components";

const INIT_REMIX = {
  head: 8,
  top: 8,
  bottom: 7,
};
const MAX_REMIX = {
  head: 10,
  top: 8,
  bottom: 8,
};

const Remix = ({ gender, style, option, remix, onChange = () => {} }) => {
  const [showRemix, setShowRemix] = useState(false);
  const [activeRemix, setActiveRemix] = useState(null);

  const handleActiveRemix = useCallback((type) => {
    setActiveRemix(type);
  }, []);

  const handleRemix = useCallback(
    (type, direction) => {
      const maxId = MAX_REMIX?.[type];
      const currentId = remix?.[type];
      const prevId = currentId - 1 < 1 ? maxId : currentId - 1;
      const nextId = currentId + 1 > maxId ? 1 : currentId + 1;

      if (direction === "prev") {
        onChange({ ...remix, [type]: prevId });
      }
      if (direction === "next") {
        onChange({ ...remix, [type]: nextId });
      }
    },
    [remix, onChange]
  );

  // dynamic assets
  const head = require(`./assets/svg/mixer/${gender}/head/${style}/${remix?.head}.svg`);
  const top = require(`./assets/svg/mixer/${gender}/top/${style}/${option}/${remix?.top}.svg`);
  const bottom = require(`./assets/svg/mixer/${gender}/bottom/${style}/${option}/${remix?.bottom}.svg`);

  return (
    <>
      <Block center middle className="remix" onClick={() => setShowRemix(true)}>
        <Block
          middle
          center
          className={`remix-label ${!showRemix ? "show" : "hidden"}`}
        >
          <Icon.Remix fill="rgba(31, 147, 255, 1)" />
          <Block className="text">Remix</Block>
        </Block>
      </Block>
      <Block
        column
        className={`remix-container ${showRemix ? "show" : "hidden"}`}
      >
        <Block column>
          <Block center space="between" className="title">
            <Block>Remix</Block>
            <Block
              className="icon-container"
              onClick={() => {
                setShowRemix(false);
                handleActiveRemix(null);
              }}
            >
              <Icon.Close fill="rgba(54, 61, 97, 0.5)" />
            </Block>
          </Block>
          <Block
            onMouseOver={() => handleActiveRemix("head")}
            className={`remix-option remix-head${
              activeRemix === "head" ? " active" : ""
            }`}
            style={{ backgroundImage: `url(${head})` }}
          >
            {activeRemix === "head" && (
              <>
                <Block
                  className="arrow left"
                  onClick={() => handleRemix("head", "prev")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(90deg)" }}
                  />
                </Block>
                <Block
                  className="arrow right"
                  onClick={() => handleRemix("head", "next")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(30deg)" }}
                  />
                </Block>
              </>
            )}
          </Block>

          <Block
            onMouseOver={() => handleActiveRemix("top")}
            className={`remix-option remix-top${
              activeRemix === "top" ? " active" : ""
            }`}
            style={{ backgroundImage: `url(${top})` }}
          >
            {activeRemix === "top" && (
              <>
                <Block
                  className="arrow left"
                  onClick={() => handleRemix("top", "prev")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(90deg)" }}
                  />
                </Block>
                <Block
                  className="arrow right"
                  onClick={() => handleRemix("top", "next")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(30deg)" }}
                  />
                </Block>
              </>
            )}
          </Block>
          <Block
            onMouseOver={() => handleActiveRemix("bottom")}
            className={`remix-option remix-bottom${
              activeRemix === "bottom" ? " active" : ""
            }`}
            style={{ backgroundImage: `url(${bottom})` }}
          >
            {activeRemix === "bottom" && (
              <>
                <Block
                  className="arrow left"
                  onClick={() => handleRemix("bottom", "prev")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(90deg)" }}
                  />
                </Block>
                <Block
                  className="arrow right"
                  onClick={() => handleRemix("bottom", "next")}
                >
                  <Icon.Down
                    fill="white"
                    style={{ transform: "rotate(30deg)" }}
                  />
                </Block>
              </>
            )}
          </Block>
        </Block>
      </Block>
    </>
  );
};

const Backdrops = ({ visible }) => {
  const [showBackdrops, setShowBackdrops] = useState(false);

  return (
    <>
      <Block
        center
        middle
        className="backdrops"
        onClick={() => setShowBackdrops(true)}
      >
        <Block
          middle
          center
          className={`backdrops-label ${
            !showBackdrops && visible ? "show" : "hidden"
          }`}
        >
          <Icon.Backdrops fill="rgba(31, 147, 255, 1)" />
          <Block className="text">Backdrops</Block>
        </Block>
      </Block>
      <Block
        className={`backdrops-container column ${
          showBackdrops ? "show" : "hidden"
        }`}
      >
        <Block center space="between" className="title">
          <Block>Backdrops</Block>
          <Block
            className="icon-container"
            onClick={() => setShowBackdrops(false)}
          >
            <Icon.Close fill="rgba(54, 61, 97, 0.5)" />
          </Block>
        </Block>
        <Block column className="backdrops-options">
          <Block row wrap space="between">
            <Block className="flex-1 option" />
            <Block className="flex-1 option" />
          </Block>
          <Block row wrap space="between">
            <Block className="flex-1 option" />
            <Block className="flex-1 option" />
          </Block>
          <Block row wrap space="between">
            <Block className="flex-1 option" />
            <Block className="flex-1 option" />
          </Block>
        </Block>
      </Block>
    </>
  );
};

const Character = ({ gender = "male", onChange = () => {} }) => {
  // toggle builder views
  const [showOptions, setShowOptions] = useState(true);
  const [showStyles, setShowStyles] = useState(true);
  const [showBackdrops, setBackdrops] = useState(false);

  // builder methods
  const [remix, setRemix] = useState(INIT_REMIX);
  const [option, setOption] = useState("walking");
  const [style, setStyle] = useState("colorful");

  // dynamic assets
  const head = require(`./assets/svg/${gender}/head/${style}/${remix?.head}.svg`);
  const top = require(`./assets/svg/${gender}/top/${style}/${option}/${remix?.top}.svg`);
  const bottom = require(`./assets/svg/${gender}/bottom/${style}/${option}/${remix?.bottom}.svg`);

  const handleRemix = useCallback((value) => {
    setRemix(value);
  }, []);

  const handleStyle = useCallback((type) => {
    if (type === "colorful") {
      setStyle(type);
    }
  }, []);

  useEffect(() => {
    onChange({ head, top, bottom });
  }, [head, top, bottom, onChange]);

  return (
    <Block
      center
      middle
      space="between"
      className="flex-1 builder"
      onMouseOver={() => setBackdrops(true)}
      onMouseOut={() => setBackdrops(false)}
    >
      <Block column>
        <Block onClick={() => setShowOptions(!showOptions)} className="toggler">
          <Icon.Poses fill="rgba(31, 147, 255, 0.5)" />
        </Block>
        <Block
          column
          className={`${showOptions ? "option-show" : "option-hidden"}`}
        >
          <Block
            center
            className={`row ${option === "walking" ? "active" : "inactive"}`}
          >
            <Block className="icon" onClick={() => setOption("walking")}>
              <Icon.Walking
                fill={`${
                  option === "walking" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
            <Block>Walking</Block>
          </Block>
          <Block
            center
            className={`row ${option === "running" ? "active" : "inactive"}`}
          >
            <Block className="icon" onClick={() => setOption("running")}>
              <Icon.Running
                fill={`${
                  option === "running" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
            <Block>Running</Block>
          </Block>
          <Block
            center
            className={`row ${option === "standing" ? "active" : "inactive"}`}
          >
            <Block className="icon">
              <Icon.Standing
                fill={`${
                  option === "standing" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
            <Block>Standing</Block>
          </Block>
          <Block
            center
            className={`row ${option === "cycling" ? "active" : "inactive"}`}
          >
            <Block className="icon">
              <Icon.Cycling
                fill={`${
                  option === "cycling" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
            <Block>Cycling</Block>
          </Block>
        </Block>
      </Block>
      <Block column center className="character">
        <img src={head} className="head" alt="" />
        <img src={top} className="top" alt="" />
        <img src={bottom} className="bottom" alt="" />

        <Backdrops visible={showBackdrops} />
        <Remix
          style={style}
          remix={remix}
          gender={gender}
          option={option}
          onChange={handleRemix}
        />
      </Block>
      <Block column>
        <Block
          right
          onClick={() => setShowStyles(!showStyles)}
          className="toggler self-end"
        >
          <Icon.Styles fill="rgba(31, 147, 255, 0.5)" />
        </Block>
        <Block
          column
          className={`${showStyles ? "style-show" : "style-hidden"}`}
        >
          <Block
            center
            right
            className={`row ${style === "colorful" ? "active" : "inactive"}`}
          >
            <Block>Colorful</Block>
            <Block
              className="icon reverse"
              onClick={() => handleStyle("colorful")}
            >
              <Icon.Colorful
                fill={`${
                  style === "colorful" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
          </Block>
          <Block
            center
            right
            className={`row ${style === "duotone" ? "active" : "inactive"}`}
          >
            <Block>Duotone</Block>
            <Block
              className="icon reverse"
              onClick={() => handleStyle("duotone")}
            >
              <Icon.Duotone
                fill={`${
                  style === "duotone" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
          </Block>
          <Block
            center
            right
            className={`row ${style === "outline" ? "active" : "inactive"}`}
          >
            <Block>Outline</Block>
            <Block
              className="icon reverse"
              onClick={() => handleStyle("outline")}
            >
              <Icon.Outline
                fill={`${
                  style === "outline" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
          </Block>
          <Block
            center
            right
            className={`row ${style === "something" ? "active" : "inactive"}`}
          >
            <Block>Something</Block>
            <Block
              className="icon reverse"
              onClick={() => handleStyle("something")}
            >
              <Icon.Outline
                fill={`${
                  style === "something" ? "#ff3e3e" : "rgba(54, 61, 97, 0.65)"
                }`}
              />
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

const Sapiens = () => {
  const [gender, setGender] = useState("male");
  const [character, setCharacter] = useState({});

  const getSVG = (url) =>
    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        return parser.parseFromString(data, "text/xml").querySelector("svg");
      });

  const handleExport = useCallback(
    async (type) => {
      // get svg files
      const svgHead = await getSVG(character.head);
      const svgTop = await getSVG(character.top);
      const svgBottom = await getSVG(character.bottom);

      // read & refactor
      const svgRegex = /paint/gi; // fix - rename for same defs id
      const svgHeadContent = svgHead.innerHTML.replace(svgRegex, "head");
      const svgTopContent = svgTop.innerHTML.replace(svgRegex, "top");
      const svgBottomContent = svgBottom.innerHTML.replace(svgRegex, "bottom");
      const svgHeader =
        '<svg width="480" height="480" viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">';
      const svgCharacter = `${svgHeader}${svgHeadContent}${svgBottomContent}${svgTopContent}</svg>`;
      const svgData = new Blob([svgCharacter], { type: "image/svg+xml" });

      if (type === "svg") {
        fileSaver.saveAs(svgData, "sapiens.svg");
      }
      if (type === "png") {
        const png = await svg2png({
          input: svgCharacter.trim(),
          encoding: "buffer",
        });
        const pngData = new Blob([png], { type: "image/png" });
        fileSaver.saveAs(pngData, "sapiens.png");
      }
    },
    [character]
  );

  return (
    <Block column className="flex-1">
      <Header onChange={setGender} onExport={handleExport} />
      <Character gender={gender} onChange={setCharacter} />
      <Footer />
    </Block>
  );
};

export default Sapiens;

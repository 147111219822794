import React from "react";

const Block = ({
  flex = true,
  noflex = false,
  row = false,
  column = false,
  center = false,
  middle = false,
  left = false,
  right = false,
  top = false,
  bottom = false,
  space = null,
  wrap = false,
  className = "",
  children,
  ...props
}) => {
  const styles = new Set([
    flex && !noflex && "flex",
    row && "row",
    column && "column",
    center && "center",
    middle && "middle",
    left && "left",
    right && "right",
    top && "top",
    bottom && "bottom",
    wrap && "wrap",
    space && `space-${space}`,
  ]);

  const classNames = [...styles, className]?.filter(Boolean)?.join(" ");

  return (
    <section
      {...props}
      className={classNames}
      style={{ display: flex, ...props.style }}
    >
      {children}
    </section>
  );
};

export default Block;

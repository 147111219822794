import React, { useState, useCallback } from "react";

// images
import logo from "../assets/logo.svg";

// custom components
import Block from "./Block";
import Icon from "./Icon";

const Header = ({ onChange = () => {}, onExport = () => {} }) => {
  const [gender, setGender] = useState("male");
  const [showExport, setShowExport] = useState(false);

  const handleGender = useCallback(
    (value) => {
      setGender(value);
      // onChange(value);
    },
    [setGender]
  );

  return (
    <header className="flex row space-between center">
      <Block className="logo">
        <img src={logo} alt="" />
      </Block>
      <Block className="center gender">
        <Block column>
          <Block
            className={`gender-button center middle ${
              gender === "male" ? "active" : "inactive"
            }`}
            onClick={() => gender !== "male" && handleGender("male")}
          >
            <Icon.Male active={gender === "male"} />
          </Block>
          <Block
            middle
            className={`gender-title ${gender === "male" ? "show" : "hidden"}`}
          >
            Male
          </Block>
        </Block>
        <Block column>
          <Block
            className={`gender-button center middle ${
              gender === "female" ? "active" : "inactive"
            }`}
            onClick={() => gender !== "female" && handleGender("female")}
          >
            <Icon.Female active={gender === "female"} />
          </Block>
          <Block
            middle
            className={`gender-title ${
              gender === "female" ? "show" : "hidden"
            }`}
          >
            Female
          </Block>
        </Block>
      </Block>
      <Block
        column
        onMouseOver={() => setShowExport(true)}
        onMouseOut={() => setShowExport(false)}
        className={`export ${showExport ? "no-bottom-radius" : ""}`}
      >
        <Block space="between" className="export-title">
          <Block>Export</Block>
          <Icon.Down
            fill={"white"}
            style={{
              transform: `${showExport ? "rotate(-180deg)" : "rotate(0)"}`,
            }}
          />
        </Block>
        <Block column className={showExport ? "export-show" : "export-hidden"}>
          <Block className="export-row" onClick={() => onExport("svg")}>
            SVG
          </Block>
          <Block className="export-row" onClick={() => onExport("png")}>
            PNG
          </Block>
        </Block>
      </Block>
    </header>
  );
};

export default Header;
